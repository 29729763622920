import React, { useEffect, useState } from 'react';

import { isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { COLORS } from 'theme/constants';
import { ColoredBox } from 'components';
import { SuccessActionsDialog } from 'components/Dialogs';
import { PLANOGRAM_VERSION_STATUS } from 'pages/planograms/constants';

import { ErrorVersion } from '../ErrorVersion/ErrorVersion';
import { InProgressVersion } from '../InProgressVersion/InProgressVersion';
import { LegacyAdditionalFields } from '../LegacyAdditionalFields/LegacyAdditionalFields';
import { PlanogramVersion } from '../PlanogramVersion/PlanogramVersion';
import { PlanogramVersionConfirmation } from '../PlanogramVersionConfirmation/PlanogramVersionConfirmation';

const defaultConfirmationParams = { action: '', versionId: 0 };

export function PlanogramVersionsList({
  planogramVersions,
  updatePlanogram,
  handleServerError,
  planogramId,
  planogramName,
  isLegacy,
  editPlanogramVersion,
  regenerateCredentials,
  handleCloneAction,
}) {
  const { t } = useTranslation();
  const [successActionMessages, setSuccessActionMessages] = useState([]);
  const [initialPlanogramVersions, setInitialPlanogramVersions] = useState(planogramVersions);
  const [versionsList, setVersionsList] = useState({});
  const [updatedVersion, setUpdatedVersion] = useState('');
  const [waitingForUpdate, setWaitingForUpdate] = useState({
    [PLANOGRAM_VERSION_STATUS.drafting]: false,
    [PLANOGRAM_VERSION_STATUS.generating]: false,
    [PLANOGRAM_VERSION_STATUS.publishing]: false,
  });
  const [confirmationParams, setConfirmationParams] = useState(defaultConfirmationParams);

  useEffect(() => {
    if (!isEqual(initialPlanogramVersions && planogramVersions)) {
      const newVersionsList = {
        published: getVersion(PLANOGRAM_VERSION_STATUS.published),
        publishing: getVersion(PLANOGRAM_VERSION_STATUS.publishing),
        generated: getVersion(PLANOGRAM_VERSION_STATUS.generated),
        generating: getVersion(PLANOGRAM_VERSION_STATUS.generating),
        draft: getVersion(PLANOGRAM_VERSION_STATUS.draft),
        drafting: getVersion(PLANOGRAM_VERSION_STATUS.drafting),
        errorGenerating: getVersion(PLANOGRAM_VERSION_STATUS.errorGenerating),
        publishedDrafted: getVersionsArray(PLANOGRAM_VERSION_STATUS.publishedDrafted),
      };

      checkNeedUpdatedMsg(newVersionsList);

      setVersionsList(newVersionsList);
      setInitialPlanogramVersions(planogramVersions);
    }
  }, [planogramVersions]);

  const checkNeedUpdatedMsg = newVersionsList => {
    if ((versionsList.drafting || waitingForUpdate.drafting) && !newVersionsList.drafting && newVersionsList.draft) {
      setSuccessActionMessages(['Sphere version is cloned']);
      setUpdatedVersion(PLANOGRAM_VERSION_STATUS.draft);
      handleSetWaitingForUpdate({ [PLANOGRAM_VERSION_STATUS.drafting]: false });
    }
    if (
      (versionsList.generating || waitingForUpdate.generating) &&
      !newVersionsList.generating &&
      newVersionsList.generated
    ) {
      setSuccessActionMessages(['Sphere version is generated']);
      setUpdatedVersion(PLANOGRAM_VERSION_STATUS.generated);
      handleSetWaitingForUpdate({ [PLANOGRAM_VERSION_STATUS.generating]: false });
    }
    if (
      (versionsList.publishing || waitingForUpdate.publishing) &&
      !newVersionsList.publishing &&
      newVersionsList.published
    ) {
      setSuccessActionMessages(['Sphere version is published']);
      setUpdatedVersion(PLANOGRAM_VERSION_STATUS.published);
      handleSetWaitingForUpdate({ [PLANOGRAM_VERSION_STATUS.publishing]: false });
    }
  };

  const handleSetConfirmationParams = (action, versionId) => () => setConfirmationParams({ action, versionId });

  const handleCloseConfirmationDialog = () => setConfirmationParams(defaultConfirmationParams);

  const handleCloseOnSuccessDialog = () => setSuccessActionMessages([]);

  const getVersion = versionRule => planogramVersions.find(version => version.status === versionRule) || null;

  const getVersionsArray = versionRule => {
    const filteredRules = planogramVersions.filter(version => version.status === versionRule);

    return filteredRules.length ? filteredRules : null;
  };

  const handleSetWaitingForUpdate = data => setWaitingForUpdate(prev => ({ ...prev, data }));

  return (
    <>
      <Grid item style={{ marginBottom: '24px' }}>
        <ColoredBox variant="systemInfo">
          <ErrorOutline style={{ color: COLORS.systemInfo60, marginBottom: '12px' }} />
          <Typography style={{ margin: '12px' }}>{t('pages.planograms.showPlanogram.lastStepHint')}</Typography>
        </ColoredBox>
      </Grid>
      {!versionsList.publishing && (
        <PlanogramVersion
          planogramVersion={versionsList.published}
          title="Live Sphere"
          planogramId={planogramId}
          planogramName={planogramName}
          handleSetConfirmationParams={handleSetConfirmationParams}
          updatedVersion={isEmpty(successActionMessages) ? updatedVersion : ''}
          publishedDrafted={versionsList.publishedDrafted}
          editPlanogramVersion={editPlanogramVersion}
          regenerateCredentials={regenerateCredentials}
          handleCloneAction={handleCloneAction}
        />
      )}
      <InProgressVersion planogramVersion={versionsList.publishing} />
      <ErrorVersion planogramVersion={versionsList.errorGenerating} />
      {!versionsList.generating && (
        <PlanogramVersion
          planogramVersion={versionsList.generated}
          title="Generated"
          planogramId={planogramId}
          planogramName={planogramName}
          handleSetConfirmationParams={handleSetConfirmationParams}
          updatedVersion={isEmpty(successActionMessages) ? updatedVersion : ''}
          handleCloneAction={handleCloneAction}
        />
      )}
      <InProgressVersion planogramVersion={versionsList.generating} />
      {!versionsList.drafting && (
        <PlanogramVersion
          planogramVersion={versionsList.draft}
          planogramVersionGeneratingId={versionsList.generating?.id}
          title="Work in Progress"
          planogramId={planogramId}
          planogramName={planogramName}
          handleSetConfirmationParams={handleSetConfirmationParams}
          updatedVersion={isEmpty(successActionMessages) ? updatedVersion : ''}
          isLegacy={isLegacy}
          handleCloneAction={handleCloneAction}
        >
          <LegacyAdditionalFields
            planogramVersion={versionsList.draft}
            planogramId={planogramId}
            isLegacy={isLegacy}
            planogramName={planogramName}
            handleServerError={handleServerError}
            updatePlanogram={updatePlanogram}
          />
        </PlanogramVersion>
      )}
      <InProgressVersion planogramVersion={versionsList.drafting} />
      <SuccessActionsDialog
        open={!isEmpty(successActionMessages)}
        onClose={handleCloseOnSuccessDialog}
        messages={successActionMessages}
      />
      <PlanogramVersionConfirmation
        updatePlanogram={updatePlanogram}
        handleServerError={handleServerError}
        planogramId={planogramId}
        havePublished={!isEmpty(versionsList.published)}
        haveGenerated={!isEmpty(versionsList.generated)}
        haveDraft={!isEmpty(versionsList.draft)}
        haveGenerating={!isEmpty(versionsList.generating)}
        havePublishedDrafted={!isEmpty(versionsList.publishedDrafted)}
        setSuccessActionMessages={setSuccessActionMessages}
        confirmationParams={confirmationParams}
        handleCloseConfirmationDialog={handleCloseConfirmationDialog}
        handleSetWaitingForUpdate={handleSetWaitingForUpdate}
      />
    </>
  );
}

PlanogramVersionsList.propTypes = {
  planogramVersions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updatePlanogram: PropTypes.func.isRequired,
  handleServerError: PropTypes.func.isRequired,
  editPlanogramVersion: PropTypes.func.isRequired,
  regenerateCredentials: PropTypes.func.isRequired,
  planogramId: PropTypes.number.isRequired,
  planogramName: PropTypes.string.isRequired,
  isLegacy: PropTypes.bool,
  handleCloneAction: PropTypes.func,
};

PlanogramVersionsList.defaultProps = {
  isLegacy: false,
  handleCloneAction: () => {},
};
