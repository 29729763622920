import React, { useState, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { LinearProgress, Typography } from '@material-ui/core';

import { ButtonComponent, DefaultLandingMessage, LanguageCode, TableComponent } from 'components';
import { LINKS } from 'components/constants';
import { ConfirmationDialog, PlanogramGroupDialog, EntranceDialog } from 'components/Dialogs';
import { DEFAULT_TABLE_PARAMS } from 'components/TableComponent/constants';
import { useAuthState } from 'contexts/AuthContext';
import { useBusyProgressActions, useBusyProgressState } from 'contexts/BusyProgressContext';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { useSubmittingState } from 'hooks';
import PageWithHeader from 'layouts/PageWithHeader/PageWithHeader';
import { customDomainsModel, planogramGroupsModel } from 'models';
import { ROLES, ROUTES } from 'routes/constants';
import { Planogram, PlanogramCloningStatus, PlanogramGroup } from 'types';
import { CustomDomain } from 'types/domains';
import { ActionType } from 'types/enums';
import { TableData, TableParams } from 'types/other';
import TEST_IDS from 'constants/testIds';
import { useShoppingProviderStatus } from 'contexts/ShoppingProviderContext';
import { usePlanogramCloningState } from 'contexts/PlanogramCloningContext/PlanogramCloningContext';
import DisconnectInProgressBlocker from 'components/DisconnectStatus/DisconnectInProgressBlocker';

import { PublishedStatus } from './PublishedStatus/PublishedStatus';
import { UnderConstruction } from './UnderConstruction/UnderConstruction';

interface DialogSettings {
  handleSubmit: () => void;
  title: string;
  plGroup?: PlanogramGroup;
}

const ManagePlanograms = () => {
  const { shouldBlock } = useShoppingProviderStatus();

  const history = useHistory();
  const { t } = useTranslation();
  const { isBusy } = useBusyProgressState();
  const { withPageProgressHandler } = useBusyProgressActions();
  const { handleServerError, resetServerErrors } = useServerErrorActions();
  const planogramCloningState = usePlanogramCloningState();
  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();
  const [initialLoad, setInitialLoad] = useState(true);
  const { role } = useAuthState().currentUser.attributes;

  const [customDomains, setCustomDomains] = useState<CustomDomain[]>([]);
  const [tableParams, setTableParams] = useState<TableParams>({ ...DEFAULT_TABLE_PARAMS });
  const [dialogSettings, setDialogSettings] = useState<DialogSettings>();
  const [paginationItemsCount, setPaginationItemsCount] = useState(0);
  const [planogramGroups, setPlanogramsGroups] = useState<PlanogramGroup[]>([]);
  const [planogramToDelete, setPlanogramToDelete] = useState<{
    planogramGroup: PlanogramGroup;
    usedAsErrorResponse: boolean;
  }>();

  useEffect(() => {
    customDomainsModel
      .getCustomDomains()
      .then(({ externalHosts }) => setCustomDomains(externalHosts))
      .catch(handleServerError);
  }, []);

  useEffect(() => {
    if (!shouldBlock) getPlanogramGroups();
  }, [tableParams, shouldBlock]);

  useEffect(() => {
    if (!planogramCloningState.id && !planogramCloningState.status && !initialLoad) {
      getPlanogramGroups();
    }
  }, [planogramCloningState]);

  const getPlanogramGroups = withPageProgressHandler(() =>
    planogramGroupsModel
      .getPlanogramGroups(tableParams)
      .then(data => {
        setPlanogramsGroups(data.planogramGroups);
        setPaginationItemsCount(data.planogramGroupsCount);
        setInitialLoad(false);
      })
      .catch(handleServerError),
  );

  const handleAddPlGroup = withHandlingSubmittingState((plGroup: PlanogramGroup) =>
    planogramGroupsModel
      .addPlanogramGroup(plGroup)
      .then(() => {
        getPlanogramGroups();
        handleCloseDialog();
      })
      .catch(handleServerError),
  );

  const handleEditPlGroup = withHandlingSubmittingState((plGroup: PlanogramGroup) =>
    planogramGroupsModel
      .editPlanogramGroup(plGroup)
      .then(() => {
        getPlanogramGroups();
        handleCloseDialog();
      })
      .catch(handleServerError),
  );

  const handleRedirect = (id: number) => history.push(ROUTES.showSphere.replace(':id', id.toString()));

  const handleCloseDialog = () => {
    setDialogSettings(undefined);
    resetServerErrors();
  };

  const handleDelete = withPageProgressHandler((id: number) => {
    setPlanogramToDelete(undefined);
    return planogramGroupsModel.deletePlanogramGroup(id).then(getPlanogramGroups).catch(handleServerError);
  });

  const handleOpenDialog = (plGroup?: PlanogramGroup) => {
    setDialogSettings(prev => ({
      ...prev,
      handleSubmit: plGroup ? handleEditPlGroup : handleAddPlGroup,
      title: plGroup
        ? t('pages.planograms.managePlanograms.dialog.editTitle')
        : t('pages.planograms.managePlanograms.dialog.addTitle'),
      plGroup,
    }));
  };

  const getPlanogramGroupRowData = (planogramGroup: PlanogramGroup): React.ReactNode[] => {
    const hasMultiplePlanograms = planogramGroup.planograms.length > 1;

    if (hasMultiplePlanograms) {
      if (isProcessing(planogramGroup)) {
        return [
          planogramGroup.name,
          undefined,
          undefined,
          planogramGroup.creator,
          undefined,
          <LinearProgress color="primary" style={{ minWidth: '160px' }} />,
          <Typography className="plClone">Copy in progress</Typography>,
        ]
      }

      return [
        planogramGroup.name,
        undefined,
        undefined,
        planogramGroup.creator,
        undefined,
        planogramGroup.updatedAt,
        planogramGroup.languageCodes.map(code => <LanguageCode code={code} key={code} />),
      ];
    }

    return [
      planogramGroup.name,
      <PublishedStatus planogram={planogramGroup.planograms[0]} />,
      <UnderConstruction planogram={planogramGroup.planograms[0]} />,
      planogramGroup.creator,
      planogramGroup.planograms[0]?.itemsCount,
      planogramGroup.updatedAt,
      <LanguageCode code={planogramGroup.planograms[0]?.languageCode} />,
    ];
  };

  const getPlanogramRowData = (planogram: Planogram) => {
    if (isProcessing(planogram)) {
      return [
        planogram.name,
        <PublishedStatus planogram={planogram}/>,
        '',
        '',
        '',
        <LinearProgress color="primary" style={{ minWidth: '160px' }} />,
        <Typography className="plClone">Copy in progress</Typography>
      ]
    }

    return [
      planogram.name,
      <PublishedStatus planogram={planogram}/>,
      <UnderConstruction planogram={planogram}/>,
      '',
      planogram.itemsCount,
      planogram.updatedAt,
      <LanguageCode code={planogram.languageCode}/>,
    ];
  };

  const isProcessing = (pl: Planogram | PlanogramGroup) =>
    (pl as Planogram)?.plStatus === PlanogramCloningStatus.PROCESSING
    || pl.id === planogramCloningState.planogramDestinationId
    || pl.id === planogramCloningState.sourceGroupId
    || pl.id === planogramCloningState.planogramDestinationGroupId;

  const handleCheckUsedAsErrorResponse = (planogramGroup: PlanogramGroup) =>
    planogramGroupsModel
      .checkUsedAsErrorResponse(planogramGroup.id)
      .then(({ usedAsErrorResponse }) => setPlanogramToDelete({ usedAsErrorResponse, planogramGroup }));

  const tableData: TableData = useMemo(
    () => ({
      headers: [
        t('pages.planograms.managePlanograms.name'),
        t('pages.planograms.managePlanograms.published'),
        t('pages.planograms.managePlanograms.underConstruction'),
        t('pages.planograms.managePlanograms.creator'),
        t('pages.planograms.managePlanograms.items'),
        t('pages.planograms.managePlanograms.updated'),
        t('pages.planograms.managePlanograms.languages'),
      ],
      rows: planogramGroups.map(planogramGroup => {
        const hasMultipleChildren = planogramGroup.planograms.length > 1;

        return {
          cells: getPlanogramGroupRowData(planogramGroup),
          onClick: () => !isProcessing(planogramGroup.planograms[0]) && handleRedirect(planogramGroup.planograms[0].id),
          actions: [
            {
              type: ActionType.EDIT,
              onClick: () => handleOpenDialog(planogramGroup),
              testId: TEST_IDS.manageSpheres.list.buttons.edit,
            },
            {
              type: ActionType.DELETE,
              onClick: () => handleCheckUsedAsErrorResponse(planogramGroup),
              testId: TEST_IDS.manageSpheres.list.buttons.delete,
            },
          ],
          children: hasMultipleChildren
            ? planogramGroup.planograms.map(planogram => ({
                id: planogram.id,
                cells: getPlanogramRowData(planogram),
                actions: [{ type: ActionType.NONE }],
                onClick: () => !isProcessing(planogram) && handleRedirect(planogram.id),
              }))
            : [],
        };
      }),
    }),
    [planogramGroups, planogramCloningState],
  );

  if (shouldBlock) return <DisconnectInProgressBlocker />;

  return (
    <PageWithHeader
      tabTitle={t('pages.planograms.managePlanograms.tabTitle')}
      headerText={t('pages.planograms.managePlanograms.title', { paginationItemsCount })}
      button={{
        text: t('pages.planograms.managePlanograms.addSphere'),
        onClick: () => handleOpenDialog(),
      }}
    >
      <TableComponent
        data={tableData}
        count={paginationItemsCount}
        tableParams={tableParams}
        onParamsChange={params => setTableParams(prev => ({ ...prev, ...params }))}
      />
      {!isBusy &&
        paginationItemsCount === 0 &&
        (role === ROLES.clientAdmin ? (
          <>
            <DefaultLandingMessage
              title={t('pages.planograms.managePlanograms.defaultLandingTitle')}
              body={t('pages.planograms.managePlanograms.defaultLandingMessage')}
              footer={t('pages.planograms.managePlanograms.defaultLandingFooterMessage')}
              footerLink={LINKS.sphereKnowledgeBase}
              displayLogo
              button={
                <ButtonComponent
                  color="primary"
                  onClick={() => handleOpenDialog()}
                  text={t('pages.planograms.managePlanograms.addSphere')}
                />
              }
            />
            <EntranceDialog />
          </>
        ) : (
          <>
            <DefaultLandingMessage
              title={t('pages.planograms.managePlanograms.moderator.defaultLandingTitle')}
              body={t('pages.planograms.managePlanograms.moderator.defaultLandingMessage')}
              displayLogo
            />
            <EntranceDialog />
          </>
        ))
      }
      <PlanogramGroupDialog
        open={!!dialogSettings}
        title={dialogSettings?.title || ''}
        handleSubmit={dialogSettings?.handleSubmit}
        planogramGroup={dialogSettings?.plGroup}
        isLoading={isSubmitting}
        domains={customDomains}
        handleClose={handleCloseDialog}
      />
      {planogramToDelete && (
        <ConfirmationDialog
          open
          showWarningIcon={false}
          title={t('pages.planograms.managePlanograms.deleteSphereTitle')}
          confirmText={t('pages.planograms.managePlanograms.deleteSphereTitle')}
          message={
            planogramToDelete.usedAsErrorResponse
              ? t('pages.planograms.managePlanograms.deleteErrorResponseSphere', {
                  name: planogramToDelete.planogramGroup.name,
                })
              : t('pages.planograms.managePlanograms.deleteSphere', { name: planogramToDelete.planogramGroup.name })
          }
          handleYes={() => handleDelete(planogramToDelete.planogramGroup.id)}
          handleNo={() => setPlanogramToDelete(undefined)}
        />
      )}
    </PageWithHeader>
  );
};

export default ManagePlanograms;
