export const COLORS = {
  white: '#ffffff',
  dark: '#2a2a2b',
  darkPreview: '#181b25',
  darkText: '#333333',
  greyTabText: '#454545',
  greyBtnText: '#727272',
  greyText: '#808080',
  greySwitchBb: '#666c80',
  greyDarkBorder: '#979797',
  greyIcon: '#9e9e9e',
  greyDefault: '#c4c4c4',
  greyBgHover: '#d5d5d5',
  greyBorder: '#e1e1e1',
  lightGreyBlue: '#e9ebee',
  lightPreview: '#2B3147',
  infoIcon: '#223748',
  orangeLight: '#fff0e4',
  orangeBg: 'rgba(255, 122, 3, 0.2)',
  orangeBorder: '#f1cf8a',
  orange: '#ff7a03',
  background: '#fafafa',
  black: '#000',
  red: '#d32f2f',
  redError: '#f44336',
  redLight: '#fdf5f5',
  blue: '#006ced',
  blueLight: '#e7e9f8',
  purple: '#9c27b0',
  purpleLight: '#ffe2ff',
  greenLight: '#e6f7ee',
  green: '#9dccc2',
  greenDeep: '#3a9984',
  greenSphere: '#30b96b',
  violetLight: 'rgba(114, 100, 243, 0.2)',
  violet: '#b9b2f9',
  violetDeep: '#7264f3',
  bgLight: '#f5f5f5',
  bgLightPreview: '#f4f7ff',
  success: '#4caf50',
  verificationFailed: '#fef1f1',
  verificationFailedText: '#f97673',
  verificationWarning: '#d58000',

  // Color palette from Zeplin styleguide
  cozmosBlue: '#6238f9',
  cozmosBlueDisabled: '#9897c1',
  cozmosBlueLight: '#6238f919',
  cozmosPurple2: '#837a9c',
  cozmosPurple4: '#282336',
  cozmosPurple3: '#382d58',
  cozmosPurple5: '#322850',
  cozmosPurpleLight: '#837a9c33',
  cozmosPink: '#f44a64',
  cozmosOrange: '#ff7340',
  cozmosRed: '#f44336',
  cozmosRedLight: '#f4433633',
  cozmosGreen: '#3a9984',
  cozmosGreenLight: '#e6f7ee',
  cozmosGrey: '#8e8e93',
  cozmosGreyText: '#616168',
  systemInfo60: '#4452d4',
  systemInfo30: '#b1b9f9',
  systemInfo5: '#f0f2ff',
};

export const GRADIENTS = {
  cozmosGradient: `
    linear-gradient(to right, ${COLORS.cozmosBlue} 0%,${COLORS.cozmosPink} 80%, ${COLORS.cozmosOrange})
    `,
};

export const ICON_SIZE = {
  regular: '14px',
  middle: '20px',
  brand: '24px',
  big: '48px',
  very_big: '64px',
  large: '78px',
};
