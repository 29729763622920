import { Image } from 'types/images';
import { AnimationPath, ImageItem, NavigationArrow, ProductItem, SphereObject } from 'types/other';
import { ControlButtonNavigationType } from 'types/enums';

import { SharingButton } from './buttons';
import { CustomFont, FontSettingsType } from './fonts';
import { HandleServerErrorFunc } from './common';
import { AnimationSettingsType } from './animation';
import { EntranceAnimationTypes } from './entranceAnimation';
import { SphereLoader } from './sphereLoader';

export interface Planogram {
  id: number;
  creator: string;
  itemsCount: number;
  languageCode: string;
  name: string;
  planogramVersionsStatuses: string[];
  updatedAt: string;
  plStatus: PlanogramCloningStatus;
  language?: {
    id: number;
    code: string;
    name: string;
  };
}

export interface PlanogramGroup {
  id: number;
  externalHostId: number | null;
  creator: string;
  isLanding: boolean;
  languageCodes: string[];
  legacy: boolean;
  name: string;
  planograms: Planogram[];
  updatedAt: string;
}

export interface PlanogramGroupListResponse {
  planogramGroups: PlanogramGroup[];
  planogramGroupsCount: number;
}

export interface PlanogramGroupParams extends Record<string, any> {
  id: number;
  isLanding: boolean;
  legacy: boolean;
  name: string;
}

export interface PlanogramImage {
  id: number;
  imageName: string;
  isSystem: boolean;
  thumbnails: Record<string, string>;
  url: string;
}

export interface PlanogramInfoButton {
  id: number;
  color: string;
  customIconUrl: string | null;
  defaultIconUrl: string;
}

export interface PlanogramVersionNavigation {
  enabled: boolean;
  navigationType: string;
  navigationValue: string | null;
  openInNewPage: boolean;
  withoutNavigation: boolean;
  planogramVersionId: number;
}

export interface PlanogramVersionControlButtonMenuItem {
  id?: number;
  menu_id?: number;
  menuItemFont: FontSettingsType;
  navigationType: 'internal' | 'external';
  navigationValue?: string;
  controlButton: Image;
  controlButtonId: number;
  showTitle: boolean;
  title?: string;
  openInNewPage: boolean;
  withoutNavigation: boolean;
}

export interface PlanogramVersionControlButtonMenu {
  backgroundContainer?: boolean;
  backgroundTransparent?: boolean;
  showShadow: boolean;
  menuItems: PlanogramVersionControlButtonMenuItem[];
  color?: string;
}

export interface PlanogramVersionControlButton extends PlanogramVersionNavigation {
  id: number;
  controlButtonId: number;
  controlButton: PlanogramImage;
  imageSize: number;
  elementType: string;
  title: string;
  showTitle: boolean;
  showShadow: boolean;
  planogramVersionControlButtonFont: FontSettingsType;
  navigationValueSelect?: string | null;
  navigationValueUrl: string | null;
  navigationType: ControlButtonNavigationType;
  menu: PlanogramVersionControlButtonMenu;
  searchSetting: SearchSettings;
}

export interface PlanogramVersionLogo extends PlanogramVersionNavigation {
  id: number;
  logo: PlanogramImage;
  logoId: number;
}

export interface PlanogramVersionBGImage {
  id: number;
  backgroundImage: PlanogramImage;
  planogramVersionId: number;
}

export interface PlanogramVersion {
  id: number;
  cameraPosition: number;
  canGenerate: boolean;
  createdAt: string;
  editor: string;
  enabledGalleryOverlay: boolean;
  enabledOverlayColors: boolean;
  favicon: PlanogramImage;
  imageName: string | null;
  imageUrl: string | null;
  infoButtonSetting: PlanogramInfoButton;
  itemsCount: number;
  planogramVersionBackgroundImages: PlanogramVersionBGImage[] | null;
  planogramVersionLogo: PlanogramVersionLogo | null;
  primaryColor: string;
  publishedVersion: number | null;
  secondaryColor: string | null;
  startedAt: string | null;
  status: string;
  step: number | null;
  svgData: string;
  generatedLogin: string | null;
  generatedPassword: string | null;
  publishedLogin: string | null;
  publishedPassword: string | null;
  publishedProtected: boolean;
  updatedAt: string | null;
  url: string | null;
  validationError: string;
  version: number;
  webpQuality: number;
  autoGen: boolean;
  autoGenFrequency: string | null;
  autoGenTime: string | null;
  animationSettings: AnimationSettingsType | {};
  audio: {
    content_type: string;
    curator_ready: boolean;
    file_name: string;
    id: number;
    seo_desc: string;
    seo_title: string;
    url: string;
  } | null;
  otherAssets: OtherAssets[];
  audioBackgroundColor: string;
  volume: number;
  entranceAnimation: EntranceAnimationTypes;
  sphereLoader: SphereLoader;
  navigationAlignment: string;
  navigationDistributeEvenly: boolean;
  planogramVersionControlButtons: PlanogramVersionControlButton[];
  planogramVersionSetting: {
    controlButton: Image;
    controlButtonId: number;
  };
  defaultNavigation: {
    menu: PlanogramVersionControlButtonMenu;
    menuItem: PlanogramVersionControlButtonMenuItem;
    planogramVersionControlButton: PlanogramVersionControlButton;
    searchSetting: SearchSettings;
  };
  navigationArrow: NavigationArrow;
}

export interface PlanogramVersionUpdateParams {
  navigationType: string;
  navigationValue: string;
  enabled: boolean;
  controlButtonId: number | null;
  openInNewPage: boolean;
  imageSize: number;
  svgData: string;
  cameraPosition: number;
  webpQuality: number;
  enabledGalleryOverlay: boolean;
  publishedProtected: boolean;
  planogramVersion: {
    signedId: string;
  };
  colors: {
    primaryColor: string;
    secondaryColor: string;
  };
  logoId: number | null;
  faviconId: number | null;
  infoButtonSetting: {
    id: number;
    customIconSignedId: string;
    color: string;
    customIconRemoveImage: string;
  };
  autoGen: boolean;
  autoGenFrequency: string | null;
  autoGenTime: string | null;
  animationSettings: AnimationSettingsType | {};
  audioId: number | null;
  audioBackgroundColor: string | null;
  stopOtherAssetId: number | null;
  muteOtherAssetId: number | null;
  unmuteOtherAssetId: number | null;
  entranceAnimation: EntranceAnimationTypes | {};
  sphereLoader: SphereLoader | {};
  navigationAlignment: string;
  navigationDistributeEvenly: boolean;
  planogramVersionControlButtons: PlanogramVersionControlButton[];
  planogramVersionSetting: {
    controlButton: Image;
    controlButtonId: number;
  };
  navigationArrow: NavigationArrow;
}

export interface PlanogramVersionUpdateResponse extends PlanogramVersion {
  planogramVersion: PlanogramVersion;
  success: string[];
}

export interface PlanogramExtended {
  id: number;
  accessibilityDescription: string | null;
  createdAt: string;
  editable: boolean;
  isLanding: boolean;
  legacy: boolean;
  name: string;
  planogramVersions: PlanogramVersion[];
  seoDesc: string | null;
  seoTitle: string | null;
  subdomain: string;
  updatedAt: string;
}

export interface PlanogramListResponse {
  planograms: PlanogramExtended[];
  planogramsCount: number;
}

export interface PlanogramUpdateParams extends Record<string, any> {
  accessibilityDescription: string;
  isLanding?: boolean;
  legacy?: boolean;
  name: string;
  seoDesc: string;
  seoTitle: string;
}

export interface ShowUsedAsErrorResponse {
  usedAsErrorResponse: boolean;
  sphereName: string;
}

export interface DeletePlanogramParams {
  ignoreWarnings: boolean;
}

export interface PlanogramInfoFont extends CustomFont {
  planogramInfoId: number;
}

export interface PlanogramInfo {
  id: number;
  content: string | null;
  contentMarkdown: string | null;
  enabled: boolean;
  iconUrl: string | null;
  planogramInfoFonts: PlanogramInfoFont[];
  slug: string;
  title: string;
  usedOnPlanogram: boolean;
}

export interface PlanogramSharingButtonResponse {
  sharingButton: SharingButton;
  success: string[];
}

export enum PlanogramCloningStatus {
  ERROR = 'error',
  PROCESSING = 'processing',
  SUCCESS = 'success',
}

export interface PlanogramCloneParams {
  planogramId: number | undefined;
  planogramVersionId: number | undefined;
  languageIds: number[];
  sourceGroupId: number | undefined;
  planogramGroupId: number | undefined;
  planogramGroupName: string | undefined;
  planogramDestinationId?: number | undefined;
  clientName: string | undefined;
  email?: string | undefined;
}

export interface PlanogramCloneType {
  id: number;
  planogramVersionId: number;
  name: string;
  version: string;
}

export enum PlanogramCloneTypes {
  Outside = 'outside',
  Inside = 'inside',
  AnotherSpace = 'anotherSpace'
}

export interface Props {
  planogramVersion: Record<'id', number>;
  planogramId: number;
  handleServerError: HandleServerErrorFunc;
  setSuccessActionMessages: (messages: string[]) => void;
}

export interface OtherAssets {
  id: number;
  seoTitle: string;
  fileName: string;
  seoDesc: string;
  curatorReady: boolean;
  contentType: string;
  url: string;
  title: 'stop' | 'mute' | 'unmute';
}

export interface SearchSettingsFont extends CustomFont {
  searchSettingId: number;
}

export interface SearchSettingsIcon {
  id: number;
  buttonColor: string;
  identifier: string;
  inputColor?: string;
  url: string;
}

export interface SearchSettings {
  id: number;
  allowedExcludePlanogramVersion: number[];
  allowedSearchAttributes: string[];
  allowedSearchOrderBy: string[];
  allowedSearchOrderDir: string[];
  controlButton: PlanogramImage;
  enabled: boolean;
  excludePlanogramIds: number[];
  noResultImage: PlanogramImage;
  noResultSearchSettingFont: SearchSettingsFont;
  noResultSearchTitle: string | null;
  searchActiveIcons: SearchSettingsIcon[];
  searchAttributes: string[];
  searchDefaultIcons: SearchSettingsIcon[];
  searchOrderBy: string;
  searchOrderDir: string;
  searchSettingFont: SearchSettingsFont;
  searchTitle: string;
}

export interface PlanogramObjectsResponse {
  clusters: SphereObject[];
  curves: SphereObject[];
  paths: AnimationPath[];
  pictures: ImageItem[];
  products: ProductItem[];
  shapes: SphereObject[];
  textAreas: SphereObject[];
  videos: SphereObject[];
}
